import { useEffect, useState } from "react"

interface ActiveProjectsProps {
  readonly allProjects: ReadonlyArray<(
    Pick<GatsbyTypes.WpProject, 'title' | 'id' | 'uri'>
    & {
      readonly featuredImage: GatsbyTypes.Maybe<{
        readonly image: GatsbyTypes.Maybe<Pick<GatsbyTypes.WpMediaItem, 'gatsbyImage' | 'description' | 'title' | 'altText'>>
      }>,
      readonly acf: GatsbyTypes.Maybe<Pick<GatsbyTypes.WpProject_Projects, 'projectDescription'>>,
      readonly categories: GatsbyTypes.Maybe<{
        readonly nodes: GatsbyTypes.Maybe<ReadonlyArray<GatsbyTypes.Maybe<Pick<GatsbyTypes.WpCategory, 'name' | 'slug'>>>>
      }>
    }
  )>,
  activeCategory: string,
}

export default function useActiveProjects( props: ActiveProjectsProps ) {

  const { allProjects, activeCategory } = props

  const [activeProjects, setActiveProjects] = useState(allProjects)

  // Sets the project for Masonry when the active category is updated in Context
  useEffect(() => {
    setActiveProjects(filterActiveProjects(allProjects))
  }, [activeCategory])


  // Filters projects for the active category
  const filterActiveProjects = (allProjects: ReadonlyArray<(
    Pick<GatsbyTypes.WpProject, 'title' | 'id' | 'uri'>
    & {
      readonly featuredImage: GatsbyTypes.Maybe<{
        readonly image: GatsbyTypes.Maybe<Pick<GatsbyTypes.WpMediaItem, 'gatsbyImage' | 'title' | 'description' | 'altText'>>
      }>,
      readonly acf: GatsbyTypes.Maybe<Pick<GatsbyTypes.WpProject_Projects, 'projectDescription'>>,
      readonly categories: GatsbyTypes.Maybe<{
        readonly nodes: GatsbyTypes.Maybe<ReadonlyArray<GatsbyTypes.Maybe<Pick<GatsbyTypes.WpCategory, 'name' | 'slug'>>>>
      }>
    }
  )>) => {

    if (activeCategory === `latest`) return allProjects.slice(0, 30);

    return allProjects.filter((project) =>
      project.categories?.nodes?.some((node) => node?.slug === activeCategory)
    )
  }



  return activeProjects

}