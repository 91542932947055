import React from 'react'

const Footer = () => {

  return (
    <footer className="ml-auto sm:ml-0 text-left self-end relatve pb-5 text-xs">
      <hr className="block my-2 w-16 border-black text-right" />
      <a href="https://www.instagram.com/nehakale/?hl=en">Instagram</a>
      <br />
      <a href="https://au.linkedin.com/pub/neha-kale/27/419/889">Linked-in</a>
      <br />....
      <br />
      <a href="https://www.gatsbyjs.com" target="_blank">Gatsby</a> website
      <br />
      by <a href="https://www.linkedin.com/in/stevewestdeveloper/" target="_blank">Steve West</a>
      <br />....
      <br />
      Design by
      <br />
      <a href="https://jacinabox.com.au/" target="_blank">Jac in a Box</a>
      <br />....
      <br />
      © {new Date().getFullYear()}
    </footer>
  )
}

export default Footer