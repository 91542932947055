import React, { useState, createContext, useMemo, SetStateAction, Dispatch } from "react"

import Header from "./Header"
import Footer from "./Footer"
import LeftColumn from "./LeftColumn"
import Seo from "./Seo";

interface LayoutProps {
  pageTitle?: string,
  isHomePage?: boolean,
  children: React.ReactNode,
  location?: Location,
  categories?: Array<GatsbyTypes.Maybe<Pick<GatsbyTypes.WpCategory, "name" | "slug">>> | undefined
}


const Layout = ({ pageTitle, isHomePage = false, children, categories }: LayoutProps) => {

  return (
    <div className="container-fluid sm:container mx-auto flex flex-col font-serif z-10" data-is-root-path={isHomePage}>
      {isHomePage && <Seo title={`Home`} />}
      <Header isHomePage={isHomePage} />
      <main className={`px-5 flex-1 grid grid-cols-1 gap-2
        ${isHomePage ? 'md:grid-cols-homepage' : 'md:grid-cols-page'} `}>
        <LeftColumn isHomePage={isHomePage} pageTitle={pageTitle!} categories={categories} />
        {children}
        <Footer />
      </main>
    </div>
  )
}

export default Layout
