import React, { SetStateAction } from 'react'

interface HamburgerProps {
  menuOpen: Boolean,
  toggleHandler: React.Dispatch<SetStateAction<boolean>>
}
const Hamburger = ({menuOpen, toggleHandler}: HamburgerProps) => {
  return (
    <div className='absolute right-0 top-[3.8rem] w-20 h-12 pl-7 pr-3 pb-[18px]  flex justify-between flex-col z-50
      cursor-pointer visible sm:invisible'
      onClick={() => toggleHandler(!menuOpen)}
    >
      <div className={`w-10 h-[0.25rem] border-r-2 bg-black origin-[1px] transition-all
        ${menuOpen ? 'rotate-45' : 'rotate-0'}
      `} />
      <div className={`burger-2 w-10 h-[0.25rem] border-r-2 bg-black origin-[1px] transition-all
        ${menuOpen ? 'translate-x-100  opacity-0' : 'translate-x-0  opacity-1'}
      `} />
      <div className={`burger-3 w-10 h-[0.25rem] border-r-2 bg-black origin-[1px] transition-all
        ${menuOpen ? '-rotate-45' : 'rotate-0'}
      `} />
    </div>
  )
}

export default Hamburger