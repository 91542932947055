import React, { createContext, useContext, useEffect, useMemo, useState, SetStateAction, Dispatch } from "react"
import { graphql } from "gatsby"

import useSidebarCategories from "../hooks/useSidebarCategories"

import Layout from "../components/UI/Layout"
import Seo from "../components/UI/Seo"
import MasonryGrid from "../components/Content/MasonryGrid"

interface CategoryContext {
  activeCategory: string,
  setActiveCategory: React.Dispatch<React.SetStateAction<string>>,
}

export const CategoryContext = createContext<CategoryContext>({} as CategoryContext)


type ProjectArchiveTemplateProps = {
  data: GatsbyTypes.WordPressProjectArchiveQuery
}

const ProjectIndex = ({ data }: ProjectArchiveTemplateProps) => {
  const allProjects = data?.allWpProject?.projects

  // Got no projects?
  if (!allProjects.length) {
    return (
      <Layout pageTitle="Work">
        <Seo title="Work" />
        <p>
          No blog projects found. Add projects to your WordPress site and they'll
          appear here!
        </p>
      </Layout>
    )
  }

  const [activeCategory, setActiveCategory] = useState("latest")

  const value = useMemo(
    () => ({ activeCategory, setActiveCategory }),
    [activeCategory]
  );

  const sidebarCategories = useMemo(
    () => (useSidebarCategories({ allProjects })),
    []
  );

  // TODO:: a full re-render is occuring whenever the category is changed.
  // Re-organise if possible to avoid.
  return (
    <CategoryContext.Provider value={value}>
      <Layout pageTitle="Work" categories={sidebarCategories}>
        <Seo title="Work" description="Project Archive" />

        <MasonryGrid allProjects={allProjects} activeCategory={activeCategory} />

      </Layout>
    </CategoryContext.Provider>
  )
}

export default ProjectIndex

export const pageQuery = graphql`
  query WordPressProjectArchive {
    allWpProject(sort: {fields: [date], order: DESC}) {
      projects: nodes {
        featuredImage {
          image: node {
            gatsbyImage(width: 644, placeholder: BLURRED, layout: FULL_WIDTH, formats: [AUTO, WEBP])
            description
            title
            altText
          }
        }
        title
        id
        uri
        acf: projects {
          projectDescription
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }
  }

`
