import { Link, useStaticQuery, graphql } from 'gatsby'
import React from 'react'

import Logo from "./Logo"

interface HeaderLogoProps {
  isHomePage: boolean,
}

const HeaderLogo = ({ isHomePage }: HeaderLogoProps) => {

  const query = useStaticQuery<GatsbyTypes.LayoutQueryQuery>(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
        }
      }
    }
  `)

  const title = query?.wp?.generalSettings?.title

  if (isHomePage) {
    return (
      <h1>
        <span className="hidden">{title}</span>
        <Logo />
      </h1 >
    )
  }

  return (
    <Link to="/">
      <span className="hidden">{title}</span>
      <Logo />
    </Link>
  )
}

export default HeaderLogo
