import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

interface MasonryItemProps {
  index: number,
  project: Pick<GatsbyTypes.WpProject, 'title' | 'id' | 'uri'>
    & {
      readonly featuredImage: GatsbyTypes.Maybe<{
        readonly image: GatsbyTypes.Maybe<Pick<GatsbyTypes.WpMediaItem, 'gatsbyImage' | 'description' | 'title' | 'altText'>>
      }>,
      readonly acf: GatsbyTypes.Maybe<Pick<GatsbyTypes.WpProject_Projects, 'projectDescription'>>,
      readonly categories: GatsbyTypes.Maybe<{
        readonly nodes: GatsbyTypes.Maybe<ReadonlyArray<GatsbyTypes.Maybe<Pick<GatsbyTypes.WpCategory, 'name' | 'slug'>>>>
      }>
    }
}

const MasonryItem = ({ project, index }: MasonryItemProps) => {
  const image = project?.featuredImage?.image
  return (
    <article
      itemScope
      itemType="http://schema.org/Article"
      className={`project-list-item cursor-pointer relative`}
      >
        <a href={project.uri} title={project.title}>
          <div className="absolute z-10 top-0 left-0
            h-full w-full p-2
            transition-opacity duration-500 opacity-0 hover:opacity-100"
          >
            <div className="bg-white h-full w-full p-2 overflow-hidden">
              <hr className="block mt-0 mb-4 w-20 border-cyan-800" />
              <h2 className="text-xs font-bold not-italic">{project.title}</h2>
              <p className="text-xs">{project.acf?.projectDescription}</p>
            </div>
          </div>
          <figure className="-z-0 h-auto w-full">
            <GatsbyImage alt={image?.altText || image?.description || ``}
              image={image?.gatsbyImage!}
              loading={index > 12 ? "lazy" : "eager"}
              className="object-cover object-top w-full h-full"
              title={image?.title}
            />
          </figure>
        </a>
    </article>
  )
}

export default MasonryItem