import { Link } from 'gatsby';
import React from 'react'

const MainMenu = ({ menuOpen }: { menuOpen: boolean }) => {
  const menuNavItemClasses = `px-5 pb-4 h-[15vh] sm:h-auto`

  const menuLinkItemClasses = `text-gray-700 hover:text-red-700 w-full h-full text-center
    flex flex-col justify-center content-center text-lg text-2xl sm:text-xs`;

  return (
    <div className={`sm:visible absolute sm:relative
      transition-all duration-300 ease-in
      ${menuOpen ? `opacity-100` : `opacity-0 z-20 display:none`}
      w-full  left-0 sm:z-20 sm:opacity-100`}>
      <nav className={`site-menu bg-white/90 p-10 sm:p-0 w-full block relative ${menuOpen ? `h-[86vh]` : `hidden`} sm:block sm:h-auto`}
        role="navigation"
        aria-roledescription="Main Navigation menu">
        <ul className="flex flex-col w-full justify-around sm:flex-row sm:justify-start sm:w-auto text-sm italic">
          <li className={menuNavItemClasses}>
            <Link to="/about/"
              className={menuLinkItemClasses}
              partiallyActive={true}
              activeClassName={`line-through`}>About</Link>
          </li>
          <li className={menuNavItemClasses}>
            <Link to="/work/"
              className={menuLinkItemClasses}
              partiallyActive={true}
              activeClassName={`line-through`}>Work</Link>
          </li>
          <li className={menuNavItemClasses}>
            <Link to="/news/"
              className={menuLinkItemClasses}
              partiallyActive={true}
              activeClassName={`line-through`}>News</Link>
          </li>
          <li className={menuNavItemClasses}>
            <Link to="/contact/"
              className={menuLinkItemClasses}
              partiallyActive={true}
              activeClassName={`line-through`}>Contact</Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default MainMenu