/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"


interface SEOProps {
  description?: GatsbyTypes.Maybe<string>,
  lang?: GatsbyTypes.Maybe<string>,
  meta?: Array<{ name: string, content: string } | { property: string, content: string }>,
  title: GatsbyTypes.Maybe<string>,
}

const Seo = ({ description='', lang='en', meta=[], title }: SEOProps) => {
  const { wp, wpUser }: any = useStaticQuery(
    graphql`
      query {
        wp {
          generalSettings {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription: string = description || wp.generalSettings?.description
  const defaultTitle: string = wp.generalSettings?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
      meta={[
        { name: `author`, content: `Neha Kale`, },
        { name: `description`, content: metaDescription, },
        { property: `og:title`, content: title, },
        { property: `og:description`, content: metaDescription, },
        { property: `og:type`, content: `website`, },
        { property: `og:author`, content: `Neha Kale`, },
        { property: `og:site_name`, content: `Neha Kale`, },
        { name: `twitter:card`, content: `summary`, },
        { name: `twitter:creator`, content: `Neha_Kale`, },
        { name: `twitter:title`, content: title, },
        { name: `twitter:description`, content: metaDescription, },
      ].concat(meta)}
    />
  )
}

export default Seo
