interface SidebarCategoriesProps {
  readonly allProjects: ReadonlyArray<(
    Pick<GatsbyTypes.WpProject, 'title' | 'id' | 'uri'>
    & {
      readonly featuredImage: GatsbyTypes.Maybe<{
        readonly image: GatsbyTypes.Maybe<Pick<GatsbyTypes.WpMediaItem, 'gatsbyImage' | 'description' | 'title' | 'altText'>>
      }>,
      readonly acf: GatsbyTypes.Maybe<Pick<GatsbyTypes.WpProject_Projects, 'projectDescription'>>,
      readonly categories: GatsbyTypes.Maybe<{
        readonly nodes: GatsbyTypes.Maybe<ReadonlyArray<GatsbyTypes.Maybe<Pick<GatsbyTypes.WpCategory, 'name' | 'slug'>>>>
      }>
    }
  )>
}

export default function useSidebarCategories(props: SidebarCategoriesProps) {

  const { allProjects } = props
  // We take our projects, and determine distinct relevant categories.
  // These are passed to Layout to display in the left sidebar
  const allActiveCategories: Array<GatsbyTypes.Maybe<Pick<GatsbyTypes.WpCategory, "name" | "slug">>> = []
  allProjects
    .filter(project => project.categories !== null)
    .map(project => project.categories)
    .map(categorySet => categorySet?.nodes?.map((cat) => allActiveCategories.push(cat)))

  const distinctCategories = Array.from(
    new Set(allActiveCategories.map(cat => cat?.slug))
  )
    .map(slug => {
      return {
        slug,
        name: allActiveCategories.find(cat => cat?.slug === slug!)?.name,
      }
    })
    .sort((a, b) => a.slug!.localeCompare(b.slug!))

  // Add default category
  return [{ slug: "latest", name: "Latest", }, ...distinctCategories.reverse()]

}