import React, { useState } from 'react'

import useActiveProjects from "../../hooks/useActiveProjects"

import Masonry from "react-masonry-css"
import MasonryItem from "../../components/Content/MasonryItem"

interface MasonryGridProps {
  readonly allProjects: ReadonlyArray<(
    Pick<GatsbyTypes.WpProject, 'title' | 'id' | 'uri'>
    & {
      readonly featuredImage: GatsbyTypes.Maybe<{
        readonly image: GatsbyTypes.Maybe<Pick<GatsbyTypes.WpMediaItem, 'gatsbyImage' | 'description' | 'title' | 'altText'>>
      }>,
      readonly acf: GatsbyTypes.Maybe<Pick<GatsbyTypes.WpProject_Projects, 'projectDescription'>>,
      readonly categories: GatsbyTypes.Maybe<{
        readonly nodes: GatsbyTypes.Maybe<ReadonlyArray<GatsbyTypes.Maybe<Pick<GatsbyTypes.WpCategory, 'name' | 'slug'>>>>
      }>
    }
  )>,
  activeCategory: string,
}

const MasonryGrid = ({ allProjects, activeCategory }: MasonryGridProps) => {

  const activeProjects = useActiveProjects({ allProjects, activeCategory })

  // Masonry column breakpoints match Tailwind bp
  const breakpointColumnsObj = {
    default: 3,
    1280: 3,
    1024: 2,
    768: 1
  }

  return (
    <div className="text-left mb-36">
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="masonry-grid"
        columnClassName="masonry-grid_column">
        {activeProjects?.map((project, index) => {

          if (!project?.featuredImage?.image?.gatsbyImage) return

          return <MasonryItem key={project.id!} index={index} project={project} />

        })}
      </Masonry>
    </div>
  )
}

export default MasonryGrid