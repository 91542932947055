import React from "react";
import { Link } from "gatsby";

export interface SideBarHeaderProps {
  pageTitle: string
}

const SidebarHeader = ({ pageTitle }: SideBarHeaderProps) => {
  switch (pageTitle) {
    case `< News`:
      return <Link className="text-base not-italic cursor-pointer text-gray-700" to="/news/">
        {pageTitle}
      </Link>

    case `< Work`:
      return <Link className="text-base not-italic cursor-pointer text-gray-700" to="/work/">
        {pageTitle}
      </Link>

    default:
      return <h1 className="text-base">{pageTitle}</h1>
  }
}

export default SidebarHeader