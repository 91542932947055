import React, { useState } from 'react'

import logo from "../../images/nehalogo.png";
import logoBorder from "../../images/nehalogo-border.png";

const Logo = () => {

  const [activeImage, setActiveImage] = useState(false);

  const logoBorderClasses = `absolute top-0 transition-opacity duration-1000 ease-in-out
    ${activeImage ? `opacity-100` : `opacity-0`}`

  return (
    <div
      className="relative w-[234px] mt-10 mb-7"
      onMouseEnter={() => setActiveImage(true)}
      onMouseLeave={() => setActiveImage(false)}>
      <img src={logo} alt="Neha Kale text logo"
        width="468" height="116" />
      <img src={logoBorder} alt="Neha Kale text logo"
        width="468" height="116"
        className={logoBorderClasses} />
    </div>
  )
}

export default Logo