import { Link } from "gatsby";
import React, { MouseEventHandler, useContext, useState } from "react";

import { CategoryContext } from "../../templates/work-project-archive"
import { globalHistory } from "@reach/router"

import SidebarHeader from "./SidebarHeader"

interface LeftColumnProps {
  isHomePage: boolean,
  pageTitle: string,
  categories?: Array<GatsbyTypes.Maybe<Pick<GatsbyTypes.WpCategory, "name" | "slug">>>
}

const LeftColumn = ({ isHomePage, pageTitle, categories }: LeftColumnProps) => {

  if (isHomePage) return <p></p>

  const { activeCategory, setActiveCategory } = useContext(CategoryContext);

  const categoryClickHandler: MouseEventHandler<HTMLAnchorElement> = (event) => {
    const target = event.target as HTMLElement
    if (setActiveCategory) {
      let slug = target.dataset.slug as string
      setActiveCategory(slug)
    }
  }

  const pathname = globalHistory.location.pathname

  const categoryList = pathname === `/work` || pathname === `/work/` ?
    <ul className="flex justify-between sm:block">
      {categories?.map(cat => <li key={cat?.slug}>
        <a
          href="#"
          className={`cursor-pointer block py-2 ${cat?.slug === activeCategory ? ` text-red-600` : ``}`}
          onClick={categoryClickHandler}
          data-slug={cat?.slug}
        >{cat?.name}</a>
      </li>)}
    </ul>
    : null

  return <header>
    <SidebarHeader pageTitle={pageTitle} />
    <hr className="block my-2 w-16 border-black" />
    {categoryList}
  </header>
}

export default LeftColumn