import React, { useState } from 'react'

import HeaderLogo from './HeaderLogo';
import MainMenu from "./MainMenu";
import Hamburger from './Hamburger';

interface HeaderProps {
  isHomePage: boolean,
}

const Header = ({ isHomePage }: HeaderProps) => {

  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <header className={`pb-12 sm:pb-24 mt-2 max-w-max ${menuOpen ? `z-20` : `` }`}>
      <HeaderLogo isHomePage={isHomePage} />
      <MainMenu menuOpen={menuOpen} />
      <Hamburger menuOpen={menuOpen} toggleHandler={setMenuOpen} />
    </header>
  )
}

export default Header